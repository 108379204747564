import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

App.addListener('backButton', ({ canGoBack }) => {
    if (window.history.length > 1) {
        window.history.back();
    } else {
        App.exitApp(); // Solo si no hay historial
    }
});

if (Capacitor.getPlatform() === 'ios' && Capacitor.isNativePlatform()) {
    if (window.history.length > 1) {
        document.querySelector('body')?.classList.add('is-ios-app');
    }
}
